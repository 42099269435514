/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';



const Milestones = () => {

  return (
    <Box>
      <Box marginBottom={4}>
<br/><br/>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
          }}
        >
           Milestones
        </Typography>
        <br />
        {/* <br />  <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
          }}
        >
            NURTURE NATURE FOR A BETTER FUTURE!
        </Typography> */}
        <Typography
        component='h7'
        align='justify'
        >
         
         Welcome to the Milestones section of our website, a distinguished chronicle of our enduring achievements and pivotal moments. This curated collection celebrates the pinnacle of our academic and extracurricular triumphs. 
<br></br>
Here, we honor the remarkable results that set new benchmarks, the illustrious inter-school rolling trophy that signifies our competitive spirit and the vibrant alumni gatherings that reconnect our global community. 
<br></br>
Each milestone is a reflection of the unwavering dedication and exemplary efforts of our students, faculty and alumni. 
<br></br>
Explore this section to witness our accomplishments, which continue to inspire and propel us towards unparalleled excellence.
  

        </Typography>
      </Box>
    </Box>
  );
};


export default Milestones;